import React from 'React'
import * as d3 from 'd3'
import {
  symmetryTranslate,
  missingDimension,
  indexOfMax
} from 'tools/math.js'
import sliceMasks from 'static/slice_masks.json'

const indexToCoordinate = (index, shape) => {
    const n = shape.length;
    const coordinate = [];

    for (let i = n - 1; i >= 0; i--) {
        const size = shape[i];
        coordinate[i] = index % size;
        index = Math.floor(index / size);
    }

    return coordinate.reverse();
}

export default class LimitLocations extends React.Component {
  render () {
    console.log(this.props)
    if (
      this.props.offline
      && this.props.online
      && this.props.rods
      && this.props.station
    ) {
      let offlineColor = 1
      let offlineColorZ = 1

      const n = Math.sqrt(this.props.rods.length)
      let offline = this.props.offline
      let zoffline = null
      let online = this.props.online
      let zonline = null
      if (online.length === 3) {
        zonline = online[0]
        online = online.slice(1, 3)
        zoffline = offline[0]
        offline = offline.slice(1, 3)
      }
      const translatedOffline = symmetryTranslate(offline, n)
      const translatedOnline = symmetryTranslate(online, n)
      if (translatedOffline.toString() === translatedOnline.toString()) {
        offlineColor = .5
      }
      if (zonline === zoffline) {
        offlineColorZ = .5
      }
      const miniMask = sliceMasks[this.props.station]
      const m = 20
      const h = parseInt(this.props.h)
      const bounds = [m, h - m]
      const reverseBounds = [h - m, m]
      const xscale = d3.scaleLinear().domain([0, n/2]).range(bounds)
      const yscale = d3.scaleLinear().domain([0, n/2]).range(reverseBounds)
      const zscale = d3.scaleLinear().domain([0, 25]).range([h -10, 10])
      let squares  = []
      for (let i = 0; i < n/2; i++) {
        for (let j = 0; j < n/2; j++) {
          if (miniMask[15 * i + j] === 1) {
            squares.push([j, i])
          }
        }
      }
      const greySquares = squares.map((coord) => {
        return (
          <rect
            key={`greySquare-${coord[0]}-${coord[1]}`}
            x={xscale(coord[0])}
            y={yscale(coord[1])}
            width="5"
            height="5"
            fill="grey"
            stroke="black"
            strokeWidth="1"
          />
        );
      });
      const bigTicks = [0, 5, 10, 15, 20, 25].map((d, i) => (
        <g
          key={'bigTick' + i}
        >
          <line
            x1={h + 20}
            y1={zscale(d)}
            x2={h + 14}
            y2={zscale(d)}
            stroke='black'
          />
          <text
            x={h + 13}
            y={zscale(d) + 4}
            text-anchor='end'
            fontSize='9px'
            fontFamily='sans-serif'
          >
            {d}
          </text>
        </g>
      ))
      const smallTicks = [4, 8, 16, 20, 28, 32, 40, 44].map((d, i) => (
        <line
          key={'smallTick' + i}
          x1={h + 20}
          y1={zscale(d)}
          x2={h + 17}
          y2={zscale(d)}
          stroke='black'
        />
      ))
      return (
        <svg
          id='rods'
          x={this.props.x}
          y={this.props.y}
          height={this.props.h}
          width={parseInt(this.props.h) + 60}
        >
          {
            (zoffline)
            ? <line
                x1={h + 20}
                y1='10'
                x2={h + 20}
                y2={h - 10}
                stroke='black'
              />
            : null
          }
          {greySquares}
          <g key={'online_pt'}>
            <rect
              x={xscale(translatedOnline[0]) - 2.5}
              y={yscale(translatedOnline[1]) - 2.5}
              rx='1'
              ry='1'
              width='10'
              height='10'
              fill={d3.interpolateGreens(0)}
              stroke='black'
              stroke-width='1'
            />
          </g>
          <g key={'offline_pt'}>
            <rect
              x={xscale(translatedOffline[0]) - 2.5}
              y={yscale(translatedOffline[1]) - 2.5}
              rx='1'
              ry='1'
              width='10'
              height='10'
              fill={d3.interpolateGreens(offlineColor)}
              stroke='black'
              stroke-width='1'
            />
          </g>
          {
            (zonline)
              ? (
                <g
                  key={'onlineColorTick'}
                >
                  <rect
                    x={h + 25}
                    y={zscale(zonline)}
                    rx='1'
                    ry='1'
                    width='15'
                    height='4'
                    fill={d3.interpolateGreens(0)}
                    stroke='black'
                    stroke-width='1'
                  />
                  <rect
                    x={h + 25}
                    y={zscale(zoffline)}
                    rx='1'
                    ry='1'
                    width='15'
                    height='4'
                    fill={d3.interpolateGreens(offlineColorZ)}
                    stroke='black'
                    stroke-width='1'
                  />
                </g>
              )
              : null
          }
          {(zonline) ? bigTicks : null}
          {(zonline) ? smallTicks : null}
        </svg>
      )
    } else {
      return null
    }
  }
}
