
export const symmetryTranslate = (coordinate, width) =>{
    let [x, y] = coordinate
    const middle = width / 2
    if (y < middle) y = width - y - 1
    if (x > middle) x = width - x - 1
    if (y > (width - x - 1)) {
        let oldY = y
        y = width - x - 1
        x = width - oldY - 1
    }
    y -= middle
    return [x, y]
}

export const missingDimension = (l, shape) => {
    return l / shape.reduce((acc, val) => acc * val)
}

export const indexOfMax = arr => arr.indexOf(Math.max(...arr))