import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const GraphLabelEig = () => {
  return (
    <>
      <Row className='inline justify-content-right lead mb-3 mx-auto'>
        <Col xs='3' className='d-flex justify-content-center lead' style={{ fontWeight: '100' }}>Key</Col>
        <Col xs='1' className='d-flex justify-content-right lead' style={{ fontWeight: '100' }}>i</Col>
        <Col xs='1' className='d-flex justify-content-right lead' style={{ fontWeight: '100' }}>j</Col>
        <Col xs='1' className='d-flex justify-content-right lead' style={{ fontWeight: '100' }}>k</Col>
        <Col xs='1' className='d-flex justify-content-left lead' style={{ fontWeight: '100' }}>Axis</Col>
        <Col xs='1' className='d-flex switch justify-content-center lead' style={{ fontWeight: '100' }}>Dominant</Col>
        <Col xs='auto' />  &nbsp; &nbsp;
        <Col xs='auto' className='d-flex justify-content-left lead' style={{ fontWeight: '100' }}>Color</Col>
      </Row>
    </>
  )
}

export default GraphLabelEig
